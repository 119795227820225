export class RwDropdownItem<TPayload> {
  name: string = '';
  value: TPayload | undefined = undefined;

  constructor(name: string, value: TPayload) {
    this.name = name;
    this.value = value;
  }

  static AsRwDropdownItem = <TPayload2 extends unknown>(item: RwDropdownItem<TPayload2> | null) => item;
};
