<div class="step-navigator">
  <div class="step" [class]="{'active': stepNumber == 1}">
    <div class="number">
      <div class="circle">
        1
      </div>
    </div>
    <a href="#" (click)="toLogin($event)">{{ 'STEPS.STEP1' | translate }}</a>
  </div>
  <div class="step" [class]="{'active': stepNumber == 2}">
    <div class="number">
      <div class="circle">
        2
      </div>
    </div>
    <a [routerLink]="'/check-in'">{{ 'STEPS.STEP2' | translate }}</a>
  </div>
  <div class="step" [class]="{'active': stepNumber == 3}">
    <div class="number">
      <div class="circle">
        3
      </div>
    </div>
    <a href="#" (click)="toConfirmation($event)">{{ 'STEPS.STEP3' | translate }}</a>
  </div>
</div>
