import { Injectable } from '@angular/core';
import { Pages } from '../types/pages';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  #pageNameKeyByType: { [key in Pages]: string } = {
    [Pages.Home] : 'PAGES.HOME.TITLE',
    [Pages.Login] : 'PAGES.LOGIN.TITLE',
    [Pages.CheckIn] : 'PAGES.CHECK_IN.TITLE',
    [Pages.End] : 'PAGES.END.TITLE',
    [Pages.NotFound] : 'PAGES.NOT_FOUND.TITLE',
    [Pages.Safety] : 'PAGES.SAFETY.TITLE',
    [Pages.Result] : 'PAGES.CHECKIN_RESULT.TITLE'
  };

  #pageUrlByType: { [key in Pages]: string } = {
    [Pages.Home] : '/',
    [Pages.Login] : '/login',
    [Pages.CheckIn] : '/check-in',
    [Pages.End] : '/end',
    [Pages.NotFound]: '/not-found',
    [Pages.Safety]: '/safety',
    [Pages.Result] : '/result'
  };

  get pageNameKeyByType() { return  this.#pageNameKeyByType; }
  get pageUrlByType() { return this.#pageUrlByType; }
};
