import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalEventModel } from '../model/event/global-event-model';
import { GlobalEvent } from '../model/event/global-events';

@Injectable({
  providedIn: 'root'
})
export class EventHub {
  // @ts-ignore
  #eventEmitters: { [Key in GlobalEvent]: EventEmitter<GlobalEventModel> } = {};

  constructor() {
    // eslint-disable-next-line guard-for-in
    for (const event in GlobalEvent) {
      const typedColorString = (GlobalEvent[event] as any) as GlobalEvent;
      this.#eventEmitters[typedColorString]  = new EventEmitter();
    }
  }

  on(event: GlobalEvent): Observable<GlobalEventModel> {
    return this.#eventEmitters[event];
  }

  emit(event: GlobalEvent, payload: any | null = null) {
    const eventModel = new GlobalEventModel();
    eventModel.eventType = event;
    eventModel.payload = payload;

    this.#eventEmitters[event].emit(eventModel);
  }
};
