<nav class="top-nav">
  <div class="container">
    <div class="logo">
      <a href="#" (click)="onBackToWorkflow($event)"><svg-icon src="assets/images/logo-rockwool.svg"></svg-icon></a>
    </div>
    <div class="menu">
    </div>
    <div class="language">
      <div class="top-link back" *ngIf="workflow !== null">
        <a href="#" (click)="onBackToWorkflow($event)">{{ 'TOPNAV.BACK_TO_START' | translate }}</a>
      </div>
      <div class="current-language" (click)="onLanguageSelectorClick($event)">
        <span *ngIf="language == 'en'"><svg-icon src="assets/images/flags/gb.svg"></svg-icon>{{ 'TOPNAV.LANG_ENGLISH' | translate }}</span>
        <span *ngIf="language == 'se'"><svg-icon src="assets/images/flags/se.svg"></svg-icon>{{ 'TOPNAV.LANG_SWEDISH' | translate }}</span>
        <span *ngIf="language == 'pl'"><svg-icon src="assets/images/flags/pl.svg"></svg-icon>{{ 'TOPNAV.LANG_POLISH' | translate }}</span>
        <span *ngIf="language == 'no'"><svg-icon src="assets/images/flags/no.svg"></svg-icon>{{ 'TOPNAV.LANG_NORVEGIAN' | translate }}</span>
        <span *ngIf="language == 'hu'"><svg-icon src="assets/images/flags/hu.svg"></svg-icon>{{ 'TOPNAV.LANG_HUNGARIAN' | translate }}</span>
        <span *ngIf="language == 'empty'"><svg-icon src="assets/images/cros.svg"></svg-icon>SYSTEM</span>
        <svg-icon src="assets/images/arrow-down.svg"></svg-icon>
      </div>
      <language-select [isVisible]="languageSelectVisible" (closed)="languageSelectVisible = false"></language-select>
    </div>
  </div>
</nav>