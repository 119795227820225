import { PageService } from 'src/app/services/page-service';
import { Pages } from 'src/app/types/pages';
import { ComponentBase } from '../component-base';

export class PageBase extends ComponentBase {
  get title(): string {
    return this.pageService.pageNameKeyByType[this.pageType];
  }

  constructor(
    public pageType: Pages,
    protected pageService: PageService) {
      super();
  }
};
