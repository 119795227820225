import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service';
import { WorkflowService } from 'src/app/services/workflow-service';
import { WorkflowType } from 'src/app/types/worlflow-type';

@Component({
  selector: 'step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent implements OnInit {
  @Input()
  stepNumber: number = 1;

  constructor(
    private authService: AuthService,
    private router: Router,
    private workflowService: WorkflowService) { }

  ngOnInit(): void {
  }

  toLogin(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.authService.logOut().subscribe({
      next: () => {
        this.workflowService.setWorkflow(WorkflowType.Outbound);
        this.router.navigateByUrl('/login');
      }
    });
  }

  toConfirmation(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    if (this.workflowService.maxStep >= 3) {
      this.router.navigateByUrl('/safety');
    }
  }
}
