<div class="body" (click)="OnBodyClick()">
  <top-nav></top-nav>

  <div class="container body-container">
    <router-outlet #routeOutlet="outlet"></router-outlet>
  </div>

  <div class="container body-container" *ngIf="!routeOutlet.isActivated" style="text-align: center;">
    <loader [diameter]="128" style="display: inline-block;"></loader>
  </div>

  <footer></footer>
</div>