export class DeliveryModel {
  deliveryNumber: string = '';
  shipTo: {
    shortText: string;
    plant: string;
    name1: string;
    name2: string;
    houseNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postalCode: string;
    country: string;
    region: string;
    phone: string;
    fax: string;
  } | any = {};
  requestedDeliveryDateFrom: Date | null = null;
  requestedDeliveryDateTo: Date | null = null;
  eta: Date | null = null;
  salesOrder: string = '';
  completionDate:  Date | null = null;

  constructor() {
    //this.setDummyData();
  }

  setDummyData() {
    this.deliveryNumber = '00' + (Math.random() * 100000).toFixed() + '/' + (Math.random() * 10).toFixed();
    this.shipTo = {};
    this.shipTo.shortText = 'John Deere LTD, 61-293 Copenhagen';
    this.requestedDeliveryDateFrom = new Date(Date.now() + (Math.random() * 100000000));
    this.salesOrder = '000' + (Math.random() * 100000).toFixed();
  }
};
