import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'rw-button',
  templateUrl: './rw-button.component.html',
  styleUrls: ['./rw-button.component.scss']
})
export class RwButtonComponent extends ComponentBase {
  @Input()
  class: string = '';

  @Input()
  type: string = '';

  @Input()
  disabled: boolean = false;

  constructor() {
    super();
  }
};
