<div class="page container check-in">
  <step-indicator
    [stepNumber]="2"
    ></step-indicator>
  <div class="page-card checkin-content">
    <div class="summary ">
      <div class="cell">
        <h1>{{ 'PAGES.CHECK_IN.HEADER' | translate }} {{checkIn?.shipment?.shipmentNumber}}</h1>
        <h3>{{ 'PAGES.CHECK_IN.HINT' | translate }}</h3>
        <!-- <div class="key-value">
          <div>
            {{ 'PAGES.CHECK_IN.MSG_TO_DRIVER' | translate }}
          </div>
          <div>
            {{ checkIn?.shipment?.messageToDriver ? checkIn?.shipment?.messageToDriver : 'N/A' }}
          </div>
        </div> -->
        <div class="key-value">
          <div>
            {{ 'PAGES.CHECK_IN.MSG_FROM_RW' | translate }}
          </div>
          <div>
            {{ checkIn?.shipment?.messageToCarrier ? checkIn?.shipment?.messageToCarrier : 'N/A' }}
          </div>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="cell">
        <mat-form-field>
          <mat-label>{{ 'PAGES.CHECK_IN.DRIVER_NAME' | translate }}</mat-label>
          <input matInput [name]="'driverName'" [formControl]="driverNameControl" tabindex="1"/>
          <button mat-button *ngIf="driverNameControl.value" matSuffix aria-label="Clear"
            (click)="clearControl($event, driverNameControl)" class="clear-button">
            <svg-icon src="assets/images/cros.svg"></svg-icon>
          </button>
        </mat-form-field>

        <div class="double-control">
          <div class="cell">
            <mat-form-field>
              <mat-label>{{ 'PAGES.CHECK_IN.DRIVER_MOBILE' | translate }}</mat-label>
              <input matInput [name]="'driverMobile'" [formControl]="driverMobileControl" tabindex="2" />
              <button mat-button *ngIf="driverMobileControl.value" matSuffix aria-label="Clear"
                (click)="clearControl($event, driverMobileControl)" class="clear-button">
                <svg-icon src="assets/images/cros.svg"></svg-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field>
              <mat-label>{{ 'PAGES.CHECK_IN.LICENSE_PLATE' | translate }}</mat-label>
              <input matInput [name]="'licensePlate'" [formControl]="licensePlateControl" tabindex="3" />
              <button mat-button *ngIf="licensePlateControl.value" matSuffix aria-label="Clear"
                (click)="clearControl($event, licensePlateControl)" class="clear-button">
                <svg-icon src="assets/images/cros.svg"></svg-icon>
              </button>
            </mat-form-field>
          </div>
        </div>

        <div class="double-control">
          <div class="cell">
            <mat-form-field>
              <mat-label>{{ 'PAGES.CHECK_IN.CHECKIN_DATE' | translate }}</mat-label>
              <input matInput [name]="'checkInDate'" [formControl]="checkInDateControl" [readonly]="true" />
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field>
              <input matInput [name]="'checkInTime'" [formControl]="checkInTimeControl" [readonly]="true" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="deliveries">
      <div class="header">
        <div>{{ 'PAGES.CHECK_IN.DELIVERY' | translate }}</div>
        <div>{{ 'PAGES.CHECK_IN.SHIP_TO' | translate }}</div>
        <div>{{ 'PAGES.CHECK_IN.ETA' | translate }}</div>
      </div>
      <div
        class="delivery-row"
        *ngFor="let delivery of checkIn?.shipment?.deliveries">
        <div>{{delivery.deliveryNumber}}</div>
        <div>
          {{delivery.shipTo.shortText | maxLength:55:true }}
          <span class="address-info" *ngIf="delivery && delivery.shipTo.shortText.length && delivery.shipTo.shortText.length > 55">
            <a href="#" (click)="shopAddressPopup($event, delivery.deliveryNumber)">show</a>
            <rw-popup [class]="{'hidden': !addressPopup[delivery.deliveryNumber] }">
              <span>{{ delivery?.shipTo.shortText }}</span>
            </rw-popup>
          </span>
        </div>
        <div>{{ delivery.eta ? (delivery.eta | date:'dd.MM.yyyy, HH:mm') : 'N/A'}}</div>
      </div>
    </div>
    <div class="controls">
      <rw-button class="big" tabindex="4" (click)="onLogout($event)">
        {{ 'PAGES.CHECK_IN.LOG_OUT' | translate }}
      </rw-button>

      <rw-button class="submit big" [type]="'submit'" [disabled]="!form.valid" tabindex="5" (click)="onContinue($event)">
        {{ 'PAGES.CHECK_IN.CONTINUE' | translate }}
      </rw-button>
    </div>
  </div>
</div>