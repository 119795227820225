import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionStorageService } from './services/session-storage-service';
import { WorkflowService } from './services/workflow-service';
import { SessionStoreItem } from './types/session-store-item';

@Injectable({
  providedIn: 'root'
})
export class WorkflowGuard implements CanActivate {
  constructor(
    private router: Router,
    private workflowService: WorkflowService,
    private sessionStore: SessionStorageService) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.workflowService.currentWorkflow) {
      return of(true);
    } else {
      this.sessionStore.removeKnownItem(SessionStoreItem.ShipmentNumber);
      this.sessionStore.removeKnownItem(SessionStoreItem.IdToken);

      this.router.navigateByUrl('/start');
      return of(false);
    }
  }
};
