<div class="language-selector" [class]="{'hidden': !isVisible}">
  <div class="close">
    <svg-icon src="assets/images/cros.svg"></svg-icon>
  </div>
  <div class="selector">
    <div class="language" (click)="onLanguageSelect($event, 'en')">
      <svg-icon src="assets/images/flags/gb.svg"></svg-icon><br />
      <span>{{ 'TOPNAV.LANG_ENGLISH' | translate }}</span>
    </div>

    <div *ngIf="isDevMode" class="language" (click)="onLanguageSelect($event, 'se')">
      <svg-icon src="assets/images/flags/se.svg"></svg-icon><br />
      <span>{{ 'TOPNAV.LANG_SWEDISH' | translate }}</span>
    </div>

    <div class="language" (click)="onLanguageSelect($event, 'pl')">
      <svg-icon src="assets/images/flags/pl.svg"></svg-icon><br />
      <span>{{ 'TOPNAV.LANG_POLISH' | translate }}</span>
    </div>

    <div class="language" (click)="onLanguageSelect($event, 'hu')">
      <svg-icon src="assets/images/flags/hu.svg"></svg-icon><br />
      <span>{{ 'TOPNAV.LANG_HUNGARIAN' | translate }}</span>
    </div>

    <div *ngIf="isDevMode" class="language" (click)="onLanguageSelect($event, 'no')">
      <svg-icon src="assets/images/flags/no.svg"></svg-icon><br />
      <span>{{ 'TOPNAV.LANG_NORVEGIAN' | translate }}</span>
    </div>

    <div *ngIf="isDevMode" class="language" (click)="onLanguageSelect($event, 'empty')">
      <svg-icon src="assets/images/cros.svg"></svg-icon><br />
      <span>SYSTEM</span>
    </div>
  </div>
</div>