import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { AuthService } from 'src/app/services/auth-service';
import { EventHub } from 'src/app/services/event-hub';
import { ShipmentService } from 'src/app/services/shipment-service';
import { WorkflowService } from 'src/app/services/workflow-service';
import { WorkflowType } from 'src/app/types/worlflow-type';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent extends ComponentBase implements OnInit {
  languageSelectVisible = false;

  get shipment() {
    return this.shipmentService.shipment$;
  }

  get workflow() {
    return this.workflowService.currentWorkflow;
  }

  get language() {
    return this.langService.currentLang;
  }

  WorkflowType = WorkflowType;

  constructor(
    eventHub: EventHub,
    private langService: TranslateService,
    private shipmentService: ShipmentService,
    private workflowService: WorkflowService,
    private authService: AuthService,
    private router: Router) {
    super();

    this.subscribe(eventHub.on(GlobalEvent.BodyClick), () => {
      this.languageSelectVisible = false;
    });
   }

  ngOnInit(): void {
  }

  onLanguageSelectorClick(e: Event): void {
    this.languageSelectVisible = true;

    e.stopPropagation();
    e.preventDefault();
  }

  onBackToWorkflow(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.authService.logOut().subscribe({
      next: () => {
        this.router.navigateByUrl('/');
      }
    });
  }
};
