import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ShipmentService } from 'src/app/services/shipment-service';
import { WorkflowService } from 'src/app/services/workflow-service';
import { WorkflowType } from 'src/app/types/worlflow-type';

@Component({
  selector: 'app-end-page',
  templateUrl: './end-page.component.html',
  styleUrls: ['./end-page.component.scss']
})
export class EndPageComponent implements OnInit, OnDestroy {
  #timer: NodeJS.Timeout | null = null;

  remaining: number = 30;

  showHelp = false;

  get workflow() {
    return this.workflowService.currentWorkflow;
  }

  get checkInModel() {
    return this.shipmentService.shipmentCheckInModel;
  }

  WorkflowType = WorkflowType;

  constructor(
    private workflowService: WorkflowService,
    private shipmentService: ShipmentService,
    private router: Router,
    private route: ActivatedRoute) {
  }
  ngOnDestroy(): void {
    if (this.#timer) {
      clearTimeout(this.#timer);
    }
  }

  ngOnInit(): void {
    this.#timer = setTimeout(this.updateSeconds.bind(this), 1000);

    this.route.queryParamMap.pipe(
      tap(q => {
        if (q.has('showHelp')) {
          this.showHelp = true;
        } else {
          this.showHelp = false;
        }
      })
    ).subscribe();
  }

  updateSeconds() {
    if (this.#timer) {
      clearTimeout(this.#timer);
    }

    if (this.remaining <= 0) {
      this.workflowService.setWorkflow(null);
      this.router.navigateByUrl('/');

      return;
    }

    this.remaining = this.remaining - 1;

    this.#timer = setTimeout(this.updateSeconds.bind(this), 1000);
  }

  onClose(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.workflowService.setWorkflow(null);
    this.router.navigateByUrl('/');
  }
}
