<div class="start-page">
  <h2>{{ 'PAGES.START.HINT' | translate }}</h2>
  <div class="workflow-select">
    <div 
      class="item" 
      [class]="{'not-selected': selectedWorkflow == WorkflowType.Outbound, 'selected': selectedWorkflow == WorkflowType.Inbound}"
      (click)="onSelectWorkflow($event, WorkflowType.Inbound)">
      <div class="icons">
        <img class="transparent" src="assets/images/truck2.gif" alt="truck" />

        <svg-icon src="assets/images/arrow-right.svg"></svg-icon>
        <svg-icon src="assets/images/arrow-right.svg"></svg-icon>
        <svg-icon src="assets/images/arrow-right.svg"></svg-icon>

        <img src="assets/images/factory.gif" alt="factory" />
      </div>
      
      <h2>{{ 'PAGES.START.INBOUND' | translate }}</h2>
    </div>
    <div
      class="item"
      [class]="{'not-selected': selectedWorkflow == WorkflowType.Inbound, 'selected': selectedWorkflow == WorkflowType.Outbound}"
      (click)="onSelectWorkflow($event, WorkflowType.Outbound)">
      <div class="icons">
        <img class="transparent" src="assets/images/factory.gif" alt="factory" />

        <svg-icon src="assets/images/arrow-right.svg"></svg-icon>
        <svg-icon src="assets/images/arrow-right.svg"></svg-icon>
        <svg-icon src="assets/images/arrow-right.svg"></svg-icon>

        <img src="assets/images/truck2.gif" alt="truck" />
      </div>

      <h2>{{ 'PAGES.START.OUTBOUND' | translate }}</h2>
    </div>
  </div>
  <div class="controls">
    <rw-button type="submit" [class]="'submit big'" [disabled]="!selectedWorkflow" (click)="onContinue($event)">
      {{ 'PAGES.START.CONTINUE' | translate }}
    </rw-button>
  </div>
</div>