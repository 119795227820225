import { ShipmentModel } from './shipment-model';

export class ShipmentCheckInModel {
  shipment: ShipmentModel | null = null;
  driverName: string = '';
  licensePlate: string = '';
  driverMobile: string = '';
  checkInDate: Date | null = null;
  checkInTime: string = '';
  safetyConsent: boolean = false;
}
