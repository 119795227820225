import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ShipmentCheckInModel } from 'src/app/model/shipment/shipment-check-in-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { NotificationService } from 'src/app/services/notification-service';
import { ShipmentService } from 'src/app/services/shipment-service';
import { WorkflowService } from 'src/app/services/workflow-service';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent extends ComponentBase implements OnInit {
  checkInModel: ShipmentCheckInModel | null = null;
  isLoading: boolean = false;

  constructor(
    private shipmentService: ShipmentService,
    private notificationService: NotificationService,
    private workflowService: WorkflowService,
    private router: Router) {
    super();
   }

  ngOnInit(): void {
    this.workflowService.updateMaxStep(4);

    this.subscribe(this.shipmentService.shipment$, this.onNewShipment.bind(this));
  }

  onNewShipment(newShipment: ShipmentModel | null) {
    this.checkInModel = this.shipmentService.shipmentCheckInModel;
  }

  onChangeData(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.router.navigateByUrl('/check-in');
  }

  onCheckIn(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.isLoading = true;

    if (this.checkInModel && this.checkInModel.shipment) {
      this.shipmentService.checkInShipment(
        this.checkInModel.shipment.shipmentNumber,
        this.checkInModel.driverName,
        this.checkInModel.driverMobile,
        this.checkInModel.licensePlate).
      subscribe({
        next: (r) => {
          this.isLoading = false;
          if (r) {
            this.router.navigateByUrl('/end');
          } else {
            this.notificationService.showErrorByKey('ERRORS.FAILED_TO_CHECK_IN');
          }
        },
        error: (err) => {
          this.isLoading = false;
          this.notificationService.showErrorByKey('ERRORS.FAILED_TO_CHECK_IN');
          return throwError(err);
        }
      });
    }
  }
}
