import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AppCookieService {
  #langCookieKey = 'lang';

  constructor(private cookieService: CookieService) {

  }

  getLang() {
    return this.cookieService.get(this.#langCookieKey);
  }

  setLang(language: string) {
    const now = new Date();
    return this.cookieService.set(this.#langCookieKey, language, now.setDate(now.getDate() + 7));
  }
};
