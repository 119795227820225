import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AsEnumerable, Enumerable } from 'linq-es2015';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeliveryModel } from 'src/app/model/shipment/delivery-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { EventHub } from 'src/app/services/event-hub';
import { LoggingService } from 'src/app/services/logging-service';
import { NotificationService } from 'src/app/services/notification-service';
import { ShipmentService } from 'src/app/services/shipment-service';
import { ExecutionStatus } from 'src/app/types/execution-status';
import { ShipmentStatus } from 'src/app/types/shipment-status';
import { ControllerBase } from '../controller-base';
import { ShipmentApiModel } from '../model/shipment-api-model';
import { ShipmentChangeApiModel } from '../model/shipment-change-api-model';
import { ShipmentResponse } from '../reponse/shipment-response';
import { ShipmentsResponse } from '../reponse/shipments-response';
import { CheckInRequest } from '../request/check-in-request';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsController extends ControllerBase {
  constructor(
    http: HttpClient,
    eventHub: EventHub,
    notificationService: NotificationService,
    log: LoggingService) {
    super('shipments', http, eventHub, notificationService, log);
  }

  getShipment(shipmentNumber: string): Observable<ShipmentModel | null> {
    return this.get<ShipmentResponse>(shipmentNumber).pipe(
      map(r => {
        if (r.isSuccess && r.shipment) {
          return this.toShipmentModel(r.shipment);
        }

        return null;
      }),
      catchError(e => this.handleError(e))
    );
  }

  checkInShipment(shipmentNumber: string, request: CheckInRequest): Observable<ShipmentModel | null> {
    return this.put(`checkin/${shipmentNumber}`, request);
  }

  checkForDriverSafetyRecord(driverName: string): Observable<boolean> {
    return this.post(`safetyStatus`, { driverName });
  }
};
