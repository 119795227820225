import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NotificationSnackBarComponent } from '../components/molecules/notification-snack-bar/notification-snack-bar.component';
import { NotificationModel } from '../model/notification/notification-model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService) {
  }

  showMessage(msg: string, duration?: number) {
    const note = new NotificationModel();
    note.msg = msg;
    note.type = 'message';

    this.showNotification(note, duration);
  }

  showMessageByKey(key: string, duration?: number) {
    this.translateService.get(key).subscribe({
      next: (msg) => {
        this.showMessage(msg, duration);
      }
    });
  }

  showWarrning(msg: string, duration?: number) {
    const note = new NotificationModel();
    note.msg = msg;
    note.type = 'warning';

    this.showNotification(note, duration);
  }

  showWarrningByKey(key: string, duration?: number) {
    this.translateService.get(key).subscribe({
      next: (msg) => {
        this.showWarrning(msg, duration);
      }
    });
  }

  showError(msg: string, duration?: number) {
    const note = new NotificationModel();
    note.msg = msg;
    note.type = 'error';

    this.showNotification(note, duration);
  }

  showErrorByKey(key: string, duration?: number, suffix?: string) {
    this.translateService.get(key).subscribe({
      next: (msg) => {
        this.showError(suffix ? msg + suffix : msg, duration);
      }
    });
  }

  showSuccess(msg: string, duration: number = 3000) {
    const note = new NotificationModel();
    note.msg = msg;
    note.type = 'success';

    this.showNotification(note, duration);
  }

  showSuccessByKey(key: string, duration?: number) {
    this.translateService.get(key).subscribe({
      next: (msg) => {
        this.showSuccess(msg, duration);
      }
    });
  }

  showNotification(notification: NotificationModel, duration?: number) {
    this.snackBar.openFromComponent(NotificationSnackBarComponent, { data: notification, duration });
  }
};
