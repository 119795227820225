import { Injectable } from '@angular/core';
import { SessionStoreItem } from '../types/session-store-item';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {
  #TOKEN = 'id-token';

  hasItem(key: string) {
    return sessionStorage.getItem(key) != null;
  }

  hasKnownItem(key: SessionStoreItem) {
    return sessionStorage.getItem(key.toString()) != null;
  }

  removeItem(key: string) {
    sessionStorage.removeItem(key);
  }

  removeKnownItem(key: SessionStoreItem) {
    sessionStorage.removeItem(key.toString());
  }

  get(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  getKnowItem(key: SessionStoreItem): string | null {
    return sessionStorage.getItem(key.toString());
  }

  getObject<T>(key: string): T | null {
    const val = sessionStorage.getItem(key);

    if (val) {
      return JSON.parse(val);
    }

    return null;
  }

  set(key: string, value: string) {
    sessionStorage.setItem(key, value);
  }

  setKnownItem(key: SessionStoreItem, value: string) {
    sessionStorage.setItem(key.toString(), value);
  }

  setObject(key: string, value: any) {
    sessionStorage.setItem(key, value.toString());
  }
};
