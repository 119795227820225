import { Injectable } from '@angular/core';
import { AuthController } from './controllers/auth-controller';
import { ShipmentsController } from './controllers/shipments-controller';

@Injectable({
  providedIn: 'root'
})
export class Api {
  constructor(
    public auth: AuthController,
    public shipments: ShipmentsController) {
  }
};
