import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Api } from '../api/api';
import { CheckInRequest } from '../api/request/check-in-request';
import { GlobalEventModel } from '../model/event/global-event-model';
import { GlobalEvent } from '../model/event/global-events';
import { ShipmentCheckInModel } from '../model/shipment/shipment-check-in-model';
import { ShipmentModel } from '../model/shipment/shipment-model';
import { ExecutionStatus } from '../types/execution-status';
import { ShipmentStatus } from '../types/shipment-status';
import { EventHub } from './event-hub';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {
  #shipment: ShipmentModel | null = null;
  #shipment$: ReplaySubject<ShipmentModel | null> = new ReplaySubject(1);

  shipmentStatusNames: {[key in ShipmentStatus]: string} = {
    [ShipmentStatus.Open]: 'STATUSES.OPEN',
    [ShipmentStatus.InProgress]: 'STATUSES.INPROGRESS',
    [ShipmentStatus.Closed]: 'STATUSES.CLOSED'
  };

  executionStatusNames: {[key in ExecutionStatus]: string} = {
    [ExecutionStatus.Undefined]: 'undefined',
    [ExecutionStatus.NotCheckIn]: 'EXECUTION.NOTCHECKIN',
    [ExecutionStatus.CheckIn]: 'EXECUTION.CHECKIN',
    [ExecutionStatus.DeliveryStarted]: 'EXECUTION.DELIVERYSTARTED'
  };

  shipmentCheckInModel: ShipmentCheckInModel | null = null;

  get shipment$(): Observable<ShipmentModel | null> {
    return this.#shipment$.asObservable();
  }

  constructor(
    private api: Api,
    private eventHub: EventHub) {
    this.#shipment$.next(this.#shipment);
    this.eventHub.on(GlobalEvent.Logout).subscribe({
      next: this.onLogOut.bind(this)
    });
  }

  fetchShipment(shipmentNumber: string): Observable<boolean> {
    return this.api.shipments.getShipment(shipmentNumber).pipe(
      map(s => {

        if (s) {
          this.setShipment(s);

          return true;
        } else {
          this.setShipment(s);
          return false;
        }
      })
    );
  }

  setShipment(model: ShipmentModel | null) {
    if (model) {
      this.shipmentCheckInModel = new ShipmentCheckInModel();
      this.shipmentCheckInModel.shipment = model;
      this.shipmentCheckInModel.driverName = model.driverName;
      this.shipmentCheckInModel.driverMobile = model.driverMobile;
      this.shipmentCheckInModel.licensePlate = model.licensePlate;
      this.shipmentCheckInModel.checkInDate = model.checkInDate;
      this.shipmentCheckInModel.checkInTime = model.checkInTime;
    } else {
      this.shipmentCheckInModel = null;
    }

    this.#shipment = model;
    this.#shipment$.next(this.#shipment);
  }

  checkInShipment(shipmentNumber: string, driverName: string, driverMobile: string, licensePlate: string): Observable<boolean> {
    const model = new CheckInRequest();
    model.driverMobile = driverMobile;
    model.driverName = driverName;
    model.licensePlate = licensePlate;

    return this.api.shipments.checkInShipment(shipmentNumber, model).pipe(
      map(r => true),
      catchError((e) => {
        console.log(e);
        if (e.status === 400) {
          return of(false);
        }

        return throwError(e);
      })
    );
  }

  checkForDriverSafetyRecord(driverName: string): Observable<boolean> {
    return this.api.shipments.checkForDriverSafetyRecord(driverName).pipe(
      map(r => true),
      catchError((e) => {
        if (e.status === 404) {
          return of(false);
        }

        return throwError(e);
      })
    );
  }

  onLogOut(e: GlobalEventModel) {
    this.#shipment = null;
    this.shipmentCheckInModel = null;
    this.#shipment$.next(this.#shipment);
  }
};
