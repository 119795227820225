import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const apiVersion = '1';

@Injectable({
  providedIn: 'root'
})
export class ApiVersionInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.urlWithParams.startsWith('api')
      && !req.urlWithParams.startsWith('api/v')) {
      const versionReq = req.clone({
        url: `api/v${apiVersion}` + req.url.replace('api', '')
      });
      return next.handle(versionReq);
    } else {
      return next.handle(req);
    }
  }
};
