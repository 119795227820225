import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength'
})
export class MaxLengthPipe implements PipeTransform {

  transform(value: string | null | undefined, length: number, addTreeDots: boolean): string | null | undefined {
    if (value) {
      if (addTreeDots) {
        if (value.length > length) {
          return value.substr(0, length) + '...';
        }
      }
      return value.substr(0, Math.min(value.length, length));
    }

    return value;
  }

}
