import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ControllerBase } from '../controller-base';
import { TokenReponse } from '../reponse/token-reponse';
import { TokenRequest } from '../request/token-request';
import { NotificationService } from 'src/app/services/notification-service';
import { EventHub } from 'src/app/services/event-hub';
import { LoggingService } from 'src/app/services/logging-service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthController extends ControllerBase {
  constructor(http: HttpClient,
    eventHub: EventHub,
    notificationService: NotificationService,
    log: LoggingService) {
    super('auth', http, eventHub, notificationService, log);
  }

  token(request: TokenRequest): Observable<TokenReponse> {
    return this.post<TokenReponse>('token', request).pipe(
      tap((r) => {
        if (r.isSuccess && r.shipment) {
          r.shipmentModel = this.toShipmentModel(r.shipment);
        }
      })
    );
  }
}
