import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionStorageService } from 'src/app/services/session-storage-service';
import { SessionStoreItem } from 'src/app/types/session-store-item';

@Injectable()
export class AuthenticateInterceptor implements HttpInterceptor {
  constructor(private sessionStore: SessionStorageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const tokeValue = this.sessionStore.getKnowItem(SessionStoreItem.IdToken);
    if (tokeValue) {
      const copy = request.clone({
        setHeaders: {
          Authorization: `Bearer ${tokeValue}`
        }
      });

      return next.handle(copy);
    }

    return next.handle(request);
  }
}
