import { Injectable } from '@angular/core';
import { GlobalEventModel } from '../model/event/global-event-model';
import { GlobalEvent } from '../model/event/global-events';
import { WorkflowType } from '../types/worlflow-type';
import { EventHub } from './event-hub';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {
  currentWorkflow: WorkflowType | null = null;
  maxStep: number = 0;

  constructor(private eventHub: EventHub) {
    this.eventHub.on(GlobalEvent.Logout).subscribe({
      next: this.onLogout.bind(this)
    });
  }

  onLogout(e: GlobalEventModel) {
    this.currentWorkflow = null;
  }

  updateMaxStep(stepNumber: number) {
    if (this.maxStep < stepNumber) {
      this.maxStep = stepNumber;
    }
  }

  setWorkflow(workflow: WorkflowType | null) {
    this.maxStep = 1;
    this.currentWorkflow = workflow;
  }
};
