import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { SessionStorageService } from './services/session-storage-service';
import { ShipmentService } from './services/shipment-service';
import { SessionStoreItem } from './types/session-store-item';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sessionStore: SessionStorageService,
    private shipmentService: ShipmentService) {

  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.sessionStore.hasKnownItem(SessionStoreItem.IdToken)) {
      return this.shipmentService.shipment$.pipe(
        mergeMap((s) => {
          const shNumber = this.sessionStore.getKnowItem(SessionStoreItem.ShipmentNumber);
          if (!s && shNumber) {
            return this.shipmentService.fetchShipment(shNumber);
          } else {
            return of(true);
          }
      }));
    } else {
      this.navigateToLogin(next);
      return of(false);
    }
  }

  navigateToLogin(next: ActivatedRouteSnapshot) {
    this.router.navigate(['/login'], { });
  }
};
