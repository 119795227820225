<div class="page container safety-page">
  <div class="hint">
    {{ 'PAGES.SAFETY.HINT' | translate }}
  </div>
  <div class="video">
    <div style="width:100%; height:0; position: relative; padding-bottom:56.25%">
      <iframe [src]="videoUrl" style="width:100%; height:100%; position: absolute; top: 0; left: 0;" frameborder="0" border="0" scrolling="no" allowfullscreen="1" mozallowfullscreen="1" webkitallowfullscreen="1" gesture="media"></iframe>
    </div>
  </div>
  <div class="controls">
    <div>
      <rw-button class="TEST big" (click)="onBack($event)" *ngIf="workflow == WorkflowType.Outbound">
        {{ 'PAGES.SAFETY.BACK' | translate }}
      </rw-button>
    </div>
    <div>
      <loader [diameter]="24" *ngIf="isCheckingRecord"></loader>
      <mat-checkbox  *ngIf="workflow == WorkflowType.Outbound" [formControl]="checkBoxControl">{{ 'PAGES.SAFETY.CONFIRMATION' | translate }}</mat-checkbox>
    </div>
    <div>
      <rw-button
        class="submit big" [type]="'submit'"
        [disabled]="!checkBoxControl.value" (click)="onContinue($event)"
        *ngIf="workflow == WorkflowType.Outbound">
          {{ 'PAGES.SAFETY.CONTINUE' | translate }}
      </rw-button>
      <rw-button
        class="submit big"
        [type]="'submit'"
        (click)="onAgreeAndContinue($event)"
        *ngIf="workflow == WorkflowType.Inbound">
          {{ 'PAGES.SAFETY.AGREE_AND_CONTINUE' | translate }}
      </rw-button>
    </div>
  </div>
</div>