<div class="page container check-in">
  <div class="page-card checkin-content">
    <h1>Support needed</h1>
    Detailed description on how to handle situations, when Driver does not have a shipment number

    <br />
    <rw-button type="submnit" [class]="'submit'" (click)="goBack($event)">
      Go Back
    </rw-button>
  </div>
</div>
