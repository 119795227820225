import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { EventHub } from 'src/app/services/event-hub';
import { WorkflowService } from 'src/app/services/workflow-service';
import { WorkflowType } from 'src/app/types/worlflow-type';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent extends ComponentBase implements OnInit {
  selectedWorkflow: WorkflowType | null = null;

  WorkflowType = WorkflowType;

  constructor(
    private eventHub: EventHub,
    private workflowService: WorkflowService,
    private router: Router) {
    super();

    this.subscribe(this.eventHub.on(GlobalEvent.BodyClick), (e) => {
      this.selectedWorkflow = null;
    });
   }

  ngOnInit(): void {
  }

  onSelectWorkflow(e: Event, workflow: WorkflowType) {
    e.preventDefault();
    e.stopPropagation();

    this.selectedWorkflow = workflow;
  }

  onContinue(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    if (this.selectedWorkflow) {
      this.workflowService.setWorkflow(this.selectedWorkflow);

      if (this.selectedWorkflow === WorkflowType.Inbound) {
        this.router.navigateByUrl('/safety');
      } else {
        this.router.navigateByUrl('/login');
      }
    }
  }
}
