import { ExecutionStatus } from 'src/app/types/execution-status';
import { ShipmentStatus } from 'src/app/types/shipment-status';
import { DeliveryModel } from './delivery-model';

export class ShipmentModel {
  shipmentNumber: string = '';
  truckId: string = '';
  plannedCheckInStart: Date | null = new Date(Date.now());
  plannedCheckInEnd: Date | null = new Date(Date.now());
  checkInDate: Date | null = null;
  addressShort: string = '';
  address: string = '';
  shippingConditions: string = '';
  loadingMeter: string = '';
  specialProcess: string = '';
  distance: string = '';
  licensePlate: string = '';
  driverMobile: string = '';
  volume: string = '';
  weight: string = '';
  messageToCarrier: string = '';
  messageToDriver: string = '';
  driverName: string = '';
  checkInTime: string = '';
  plant: string = '';

  deliveries: DeliveryModel[] | null = [];

  status: ShipmentStatus = ShipmentStatus.Open;
  executionStatus: ExecutionStatus = ExecutionStatus.Undefined;

  isOpen: boolean = false;
  isDetailsLoading: boolean = false;

  constructor() {
    this.setRandomData();
  }

  private setRandomData() {
    this.shipmentNumber = (Math.random() * 100000000).toFixed(0);
    this.truckId = (Math.random() * 100000000).toFixed();
    this.addressShort = 'ROCKWOOL, Hedehusene ' + (Math.random() * 1000).toFixed();
    this.address = 'Test street 43/2\n00-123, Copenhagen\nDENMARK';
    this.plannedCheckInStart = new Date(Date.now() + (Math.random() * 1000000000));
    this.plannedCheckInEnd = new Date(this.plannedCheckInStart.getTime() + 3600000);
    this.loadingMeter = (Math.random() * 100).toFixed() + 'm';
    this.specialProcess = 'Open truck';
    this.distance = (Math.random() * 1000).toFixed() + 'km';
    this.driverName = 'Jan Kowalski';

    const status = Math.random() * 3;
    if (status > 2) {
      this.status = ShipmentStatus.Open;
    } else if (status > 1) {
      this.status = ShipmentStatus.Closed;
    } else {
      this.status = ShipmentStatus.InProgress;
    }
  }
};
