import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalEventModel } from 'src/app/model/event/global-event-model';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { ShipmentCheckInModel } from 'src/app/model/shipment/shipment-check-in-model';
import { ShipmentModel } from 'src/app/model/shipment/shipment-model';
import { AuthService } from 'src/app/services/auth-service';
import { EventHub } from 'src/app/services/event-hub';
import { ShipmentService } from 'src/app/services/shipment-service';
import { WorkflowService } from 'src/app/services/workflow-service';
import { ComponentBase } from '../../component-base';

@Component({
  selector: 'app-check-in-page',
  templateUrl: './check-in-page.component.html',
  styleUrls: ['./check-in-page.component.scss']
})
export class CheckInPageComponent extends ComponentBase implements OnInit {
  checkIn: ShipmentCheckInModel | null = null;

  addressPopup: {[Key: string]: boolean } = {};

  driverNameControl: FormControl = new FormControl();
  driverMobileControl: FormControl = new FormControl();
  licensePlateControl: FormControl = new FormControl();
  checkInDateControl: FormControl = new FormControl();
  checkInTimeControl: FormControl = new FormControl();

  form: FormGroup = new FormGroup({
    driverName: this.driverNameControl,
    driverMobile: this.driverMobileControl,
    licensePlate: this.licensePlateControl,
    checkInDate: this.checkInDateControl,
    checkInTime: this.checkInTimeControl
  });

  constructor(
    private shipmentService: ShipmentService,
    private authService: AuthService,
    private workflowService: WorkflowService,
    private router: Router,
    private eventHub: EventHub) {
    super();

    this.subscribe(this.shipmentService.shipment$, this.onNewShipment.bind(this));
    this.subscribe(this.eventHub.on(GlobalEvent.BodyClick), this.onBodyClick.bind(this));
  }

  ngOnInit(): void {
    this.workflowService.updateMaxStep(2);
  }

  clearControl(e: Event, control: AbstractControl) {
    e.stopPropagation();
    e.preventDefault();

    control.setValue('');
  }

  onNewShipment(newShipment: ShipmentModel | null) {
    this.checkIn = this.shipmentService.shipmentCheckInModel;

    if (this.checkIn) {
      const dateNow = new Date(Date.now()); // get the current time

      this.driverNameControl.setValue(this.checkIn.driverName);
      this.driverMobileControl.setValue(this.checkIn.driverMobile);
      this.licensePlateControl.setValue(this.checkIn.licensePlate);
      this.checkInDateControl.setValue(this.extractDateString(dateNow));
      this.checkInTimeControl.setValue(this.extractTimeString(dateNow));

      this.checkIn.checkInDate = dateNow;

      newShipment?.deliveries?.forEach((d) => {
        this.addressPopup[d.deliveryNumber] = false;
      });
    }
  }

  onLogout(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.workflowService.setWorkflow(null);
    this.authService.logOut().subscribe();
  }

  onContinue(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    if (this.checkIn) {
      this.checkIn.driverName = this.driverNameControl.value;
      this.checkIn.driverMobile = this.driverMobileControl.value;
      this.checkIn.licensePlate = this.licensePlateControl.value;
      this.checkIn.checkInTime = this.checkInTimeControl.value;

      if (this.workflowService.maxStep <= 3) {
        this.router.navigateByUrl('/safety');
      } else {
        this.router.navigateByUrl('/summary');
      }
    }
  }

  shopAddressPopup(e: Event, deliveryNumber: string) {
    e.stopPropagation();
    e.preventDefault();

    for (const key of Object.keys(this.addressPopup)) {
      this.addressPopup[key] = false;
    }

    this.addressPopup[deliveryNumber] = true;
  }

  onBodyClick(e: GlobalEventModel) {
    for (const key of Object.keys(this.addressPopup)) {
      this.addressPopup[key] = false;
    }
  }

  private extractTimeString(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  private extractDateString(date: Date) {
    return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
  }
}
