import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[pasteTrap]'
})
export class PasteTrapDirective {
  @Input() pasteTrap: AbstractControl | null = null;

  constructor(private el: ElementRef) {
  }

  @HostListener('paste', ['$event'])
  pasteTrapHandler(e: Event) {
    const event = e as ClipboardEvent;
    if (event) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      const clipboardData = event.clipboardData || (window as any)['clipboardData'];
      this.pasteTrap?.setValue(clipboardData.getData('Text').trim());

      e.stopPropagation();
      e.stopImmediatePropagation();
      e.preventDefault();
    }
  }
}
