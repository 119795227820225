import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { AuthGuard } from './auth-guard';
import { CheckInPageComponent } from './components/pages/check-in-page/check-in-page.component';
import { SummaryPageComponent } from './components/pages/summary-page/summary-page.component';
import { SafetyPageComponent } from './components/pages/safety-page/safety-page.component';
import { StartPageComponent } from './components/pages/start-page/start-page.component';
import { WorkflowGuard } from './workflow-guard';
import { EndPageComponent } from './components/pages/end-page/end-page.component';
import { SupportPageComponent } from './components/pages/support-page/support-page.component';

const routes: Routes = [
  { path: '', redirectTo: 'start', pathMatch: 'full'},
  { path: 'login', component: LoginPageComponent, canActivate: [WorkflowGuard] },
  { path: 'start', component: StartPageComponent },
  { path: 'check-in', component: CheckInPageComponent, canActivate: [WorkflowGuard, AuthGuard] },
  { path: 'summary', component: SummaryPageComponent, canActivate: [WorkflowGuard, AuthGuard] },
  { path: 'safety', component: SafetyPageComponent, canActivate: [WorkflowGuard] },
  { path: 'end', component: EndPageComponent, canActivate: [WorkflowGuard] },
  { path: 'support', component: SupportPageComponent, canActivate: [WorkflowGuard] },
  { path: '**', component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
