import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Api } from '../api/api';
import { GlobalEvent } from '../model/event/global-events';
import { LoginResult } from '../types/login-result';
import { SessionStoreItem } from '../types/session-store-item';
import { EventHub } from './event-hub';
import { SessionStorageService } from './session-storage-service';
import { ShipmentService } from './shipment-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private api: Api,
    private router: Router,
    private sessionStore: SessionStorageService,
    private eventHub: EventHub,
    private shipmentService: ShipmentService) {
    this.eventHub.on(GlobalEvent.LogoutRequest).subscribe({
      next: this.onLogoutRequested.bind(this)
    });
  }

  getToken(shipmentNumber: string, secret: string): Observable<LoginResult> {
    return this.api.auth.token({
      shipmentNumber,
      secret
    }).pipe(
      map(response => {
        if (response.isSuccess
          && response.token) {
            this.sessionStore.setKnownItem(SessionStoreItem.IdToken, response.token);
            this.sessionStore.setKnownItem(SessionStoreItem.ShipmentNumber, shipmentNumber);
            this.shipmentService.setShipment(response.shipmentModel);

            return LoginResult.Success;
        }

        return LoginResult.Failure;
      }),
      catchError((e) => {
        if (e.status === 400) {
          return of (LoginResult.AlreadyCheckedIn);
        }

        if (e.status === 404) {
          return of (LoginResult.NotFound);
        }

        if (e.status === 401) {
          return of (LoginResult.Failure);
        }

        return throwError(e);
      })
    );
  }

  logOut(): Observable<boolean> {
    this.onLogoutRequested();

    return of(true);
  }

  onLogoutRequested() {
    this.sessionStore.removeKnownItem(SessionStoreItem.IdToken);
    this.sessionStore.removeKnownItem(SessionStoreItem.ShipmentNumber);

    this.eventHub.emit(GlobalEvent.Logout);

    this.router.navigateByUrl('/login');
  }
};
