<div class="login-wrapper">
  <div class="login-form">
    <div class="login-container">
      <h2>{{ 'PAGES.LOGIN.WELCOME_HEADER' | translate }}</h2>
      <p class="sign-in-hint">{{ 'PAGES.LOGIN.WELCOME_HINT' | translate }}</p>

      <h3 class="log-in-header">{{ 'PAGES.LOGIN.HEADER' | translate }}</h3>

      <form (ngSubmit)="onSubmit()" name="login-form">
        <div class="example-container">
          <mat-form-field>
            <mat-label>{{ 'PAGES.LOGIN.SHIPMENT_LABEL' | translate }}</mat-label>
            <input matInput [formControl]="shipment" name="shipmentNumber" id="shipmentNumber" type="text" tabindex="1" [pasteTrap]="shipment" />
            <button mat-button *ngIf="shipment.value" matSuffix aria-label="Clear" (click)="resetShipment($event)">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-error *ngIf="shipment.invalid">{{ getErrorMessage(shipment) | translate }}</mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ 'PAGES.LOGIN.PASSWORD_LABEL' | translate }}</mat-label>
            <input matInput type="text" [formControl]="secret" name="secret" id="secret" tabindex="2" [pasteTrap]="secret" />
            <button mat-button *ngIf="secret.value" matSuffix aria-label="Clear" (click)="resetSecret($event)">
              <mat-icon>clear</mat-icon>
            </button>
            <mat-error *ngIf="secret.invalid">{{ getErrorMessage(secret) | translate }}</mat-error>
          </mat-form-field>
        </div>

        <div class="sign-in">
          <rw-button class="submit" [type]="'submit'" [disabled]="!form.valid" tabindex="4">
            <loader *ngIf="isLoading" [diameter]="16"></loader>
            {{ 'PAGES.LOGIN.SIGN_IN' | translate }}
          </rw-button>
          <br /><br />
          <rw-button tabindex="5" (click)="onNoShipmentNumber($event)">
            {{ 'PAGES.LOGIN.NO_SHIPMENT' | translate }}
          </rw-button>
          <p *ngIf="authError" class="error">{{ 'PAGES.LOGIN.AUTHENTICATION_ERROR' | translate }}</p>
          <p *ngIf="serviceError" class="error">{{ 'PAGES.LOGIN.SERVER_ERROR' | translate }}</p>
          <p *ngIf="notFoundError" class="error">{{ 'PAGES.LOGIN.FETCH_SHIPMENT_ERROR' | translate }}</p>
          <p *ngIf="alreadyCheckedIn" class="error">{{ 'PAGES.LOGIN.ALREADY_CHECKED_IN' | translate }}</p>
        </div>
      </form>
    </div>
  </div>
</div>