<div class="end-page">
  <!-- <div class="whiteboard" *ngIf="workflow == WorkflowType.Inbound">
    <h2>{{ 'PAGES.END.INBOUND_HEADER' | translate }}</h2>
    <div class="truck-check">
      <div class="check">
        <svg-icon src="assets/images/checked.svg"></svg-icon>
      </div>
      <img src="assets/images/truck.gif" />
    </div>
    <div class="message">
      <h3>{{ 'PAGES.END.INBOUND_MSG1' | translate }}</h3>
      <span class="big-text">{{ 'PAGES.END.INBOUND_MSG2' | translate }}</span>
    </div>
    <div class="controls">
      <rw-button [class]="'submit big'" type="submit" (click)="onClose($event)">
        {{ 'PAGES.END.CLOSE_WINDOW' | translate }}&nbsp;({{ this.remaining }}&nbsp;{{ 'PAGES.END.SECONDS_WORD' | translate }})
      </rw-button>
    </div>
  </div> -->

  <div class="whiteboard" *ngIf="workflow == WorkflowType.Inbound || showHelp">
    <div class="truck-check">
      <img src="assets/images/truck.gif" />
    </div>
    <div class="message">
      <h3>{{ 'PAGES.END.INBOUND_MSG' | translate }}</h3><br /><br />
    </div>
    <div class="controls">
      <rw-button [class]="'submit big'" type="submit" (click)="onClose($event)">
        {{ 'PAGES.END.CLOSE_WINDOW' | translate }}&nbsp;({{ this.remaining }}&nbsp;{{ 'PAGES.END.SECONDS_WORD' | translate }})
      </rw-button>
    </div>
  </div>

  <div class="whiteboard" *ngIf="workflow == WorkflowType.Outbound && showHelp == false">
    <h2>{{ 'PAGES.END.OUTBOUND_HEADER' | translate | format: checkInModel?.shipment?.shipmentNumber }}</h2>
    <div class="truck-check">
      <div class="check">
        <svg-icon src="assets/images/checked.svg"></svg-icon>
      </div>
      <img src="assets/images/truck.gif" />
    </div>
    <div class="message">
      <h3>{{ 'PAGES.END.OUTBOUND_MSG1' | translate }}</h3>
      <span>{{ 'PAGES.END.OUTBOUND_MSG2' | translate }}</span>
    </div>
    <div class="controls">
      <rw-button [class]="'submit big'" type="submit" (click)="onClose($event)">
        {{ 'PAGES.END.CLOSE_WINDOW' | translate }}&nbsp;({{ this.remaining }}&nbsp;{{ 'PAGES.END.SECONDS_WORD' | translate }})
      </rw-button>
    </div>
  </div>
</div>