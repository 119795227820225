import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ComponentBase } from '../../component-base';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service';
import { LoginResult } from 'src/app/types/login-result';
import { ShipmentService } from 'src/app/services/shipment-service';
import { NotificationService } from 'src/app/services/notification-service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent extends ComponentBase implements OnInit {
  form: FormGroup;

  shipment = new FormControl('', Validators.compose([Validators.pattern('[0-9]{7,10}'), Validators.required]));
  secret = new FormControl('', Validators.compose([Validators.required]));
  isLoading: boolean = false;
  authError: boolean = false;
  notFoundError: boolean = false;
  alreadyCheckedIn: boolean = false;
  serviceError: boolean = false;

  constructor(
    fb: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService) {
    super();

    this.form = fb.group({
      shipment: this.shipment,
      password: this.secret
    });
  }

  getErrorMessage(control: FormControl) {
    if (control.hasError('required')) {
      return 'PAGES.LOGIN.REQUIRED_ERROR';
    }
    return control.hasError('pattern') ? 'PAGES.LOGIN.SHIPMENT_ERROR' : '';
  }

  ngOnInit(): void {
  }

  onSubmit() {
    this.isLoading = true;
    this.authError = false;
    this.notFoundError = false;
    this.serviceError = false;
    this.alreadyCheckedIn = false;
    const shipmentNumber = this.shipment.value.padStart(10, '0');

    this.authService
      .getToken(shipmentNumber, this.secret.value)
      .subscribe({
        next: (result: LoginResult) => {
          this.isLoading = false;
          if (result === LoginResult.Success) {
            this.router.navigate(['/check-in']);
          } else if (result === LoginResult.NotFound) {
            this.notFoundError = true;
          } else if (result === LoginResult.AlreadyCheckedIn) {
            this.alreadyCheckedIn = true;
          } else {
            this.authError = true;
          }
        },
        error: (e) => {
          this.isLoading = false;
          this.serviceError = true;
          throw e;
        }
      });
  }

  resetShipment(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.serviceError = false;
    this.authError = false;
    this.notFoundError = false;

    this.shipment.setValue('');
    this.shipment.markAsPristine();
    this.shipment.markAsUntouched();
    this.secret.setValue('');
    this.secret.markAsPristine();
    this.secret.markAsUntouched();
  }

  resetSecret(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.serviceError = false;
    this.authError = false;
    this.notFoundError = false;

    this.secret.setValue('');
    this.secret.markAsPristine();
    this.secret.markAsUntouched();
  }

  onNoShipmentNumber(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    this.router.navigateByUrl('/end?showHelp=1');
  }
}
