import { Component, EventEmitter, Input, isDevMode, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppCookieService } from 'src/app/services/app-cookie-service';

@Component({
  selector: 'language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss']
})
export class LanguageSelectComponent implements OnInit {
  get isDevMode() {
    return isDevMode();
  }

  @Input()
  isVisible: boolean = false;

  @Output()
  closed: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private translateService: TranslateService,
    private cookieService: AppCookieService
  ) { }

  ngOnInit(): void {
  }

  onLanguageSelect(e: Event, language: string) {
    e.preventDefault();
    e.stopPropagation();

    this.translateService.use(language);
    this.cookieService.setLang(language);
    this.isVisible = false;
    this.closed.emit();
  }
}
