import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { ShipmentService } from 'src/app/services/shipment-service';
import { WorkflowService } from 'src/app/services/workflow-service';
import { WorkflowType } from 'src/app/types/worlflow-type';

@Component({
  selector: 'app-safety-page',
  templateUrl: './safety-page.component.html',
  styleUrls: ['./safety-page.component.scss']
})
export class SafetyPageComponent implements OnInit {
  videoUrl: SafeUrl | null = null;
  checkBoxControl: FormControl = new FormControl();
  isCheckingRecord: boolean = false;

  get workflow(): WorkflowType {
    return this.workflowService.currentWorkflow ?? WorkflowType.Inbound;
  }

  WorkflowType = WorkflowType;

  constructor(
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private workflowService: WorkflowService,
    private shipmentService: ShipmentService) { }

  ngOnInit(): void {
    this.translateService.get('PAGES.SAFETY.VIDEO_URL').pipe(
      tap(v => {
        this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(v);
      })
    ).subscribe();

    this.workflowService.updateMaxStep(3);

    if (this.shipmentService.shipmentCheckInModel) {
      this.checkBoxControl.setValue(this.shipmentService.shipmentCheckInModel?.safetyConsent);

      this.isCheckingRecord = true;
      if (this.shipmentService.shipmentCheckInModel.driverName) {
        this.shipmentService.checkForDriverSafetyRecord(this.shipmentService.shipmentCheckInModel.driverName).subscribe({
          next: (r) => {
            this.isCheckingRecord = false;
            if (r && this.shipmentService.shipmentCheckInModel) {
              this.shipmentService.shipmentCheckInModel.safetyConsent = true;
              this.checkBoxControl.setValue(true);
            }
          },
          error: (e) => {
            this.isCheckingRecord = false;
          }
        });
      }
    }
  }

  onBack(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.router.navigateByUrl('/check-in');
  }

  onContinue(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    if (this.shipmentService.shipmentCheckInModel) {
      this.shipmentService.shipmentCheckInModel.safetyConsent = true;
    }

    this.router.navigateByUrl('/summary');
  }

  onAgreeAndContinue(e: Event) {
    e.stopPropagation();
    e.preventDefault();

    this.router.navigateByUrl('/end');
  }
}
