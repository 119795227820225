import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'format'
})
export class FormatPipe implements PipeTransform {

  transform(value: unknown, ...args: any[]): unknown {
    if (!(typeof value === 'string') || args.length === 0) {
      return value;
    }

    let template = value;
    for (let i = 0; i < args.length; ++i) {
      template = template.replace(`$${i}`, args[i]);
    }

    return template;
  }

}
