<div class="page container summary-page">
  <step-indicator
    [stepNumber]="3"
    ></step-indicator>
  <div class="page-card summary-content">
    <div class="details ">
      <div class="cell">
        <h1>{{ 'PAGES.CHECKIN_RESULT.HEADER' | translate }} {{ checkInModel?.shipment?.shipmentNumber}}</h1>
        <p>
          {{ 'PAGES.CHECKIN_RESULT.HINT_LINE1' | translate }}
          <br /><br />
          {{ 'PAGES.CHECKIN_RESULT.HINT_LINE2' | translate }}
        </p>
      </div>
      <div class="cell">
        <mat-form-field>
          <mat-label>{{ 'PAGES.CHECK_IN.DRIVER_NAME' | translate }}</mat-label>
          <input matInput [name]="'driverName'" [readonly]="true" [value]="checkInModel?.driverName"/>
        </mat-form-field>

        <div class="double-control">
          <div class="cell">
            <mat-form-field>
              <mat-label>{{ 'PAGES.CHECK_IN.DRIVER_MOBILE' | translate }}</mat-label>
              <input matInput [name]="'driverMobile'" [readonly]="true" [value]="checkInModel?.driverMobile" />
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field>
              <mat-label>{{ 'PAGES.CHECK_IN.LICENSE_PLATE' | translate }}</mat-label>
              <input matInput [name]="'licensePlate'" [readonly]="true" [value]="checkInModel?.licensePlate" />
            </mat-form-field>
          </div>
        </div>

        <div class="double-control">
          <div class="cell">
            <mat-form-field>
              <mat-label>{{ 'PAGES.CHECK_IN.CHECKIN_DATE' | translate }}</mat-label>
              <input matInput [name]="'checkInDate'" [readonly]="true" [value]="checkInModel?.checkInDate | date:'dd.MM.yyyy'" />
            </mat-form-field>
          </div>
          <div class="cell">
            <mat-form-field>
              <input matInput [name]="'checkInTime'" [readonly]="true" [value]="checkInModel?.checkInTime" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer">

    </div>
    <div class="controls">
      <rw-button class="big" tabindex="4" (click)="onChangeData($event)">
        {{ 'PAGES.CHECKIN_RESULT.CHANGE_DATA' | translate }}
      </rw-button>

      <rw-button class="submit big" [type]="'submit'" tabindex="5" (click)="onCheckIn($event)">
        <loader *ngIf="isLoading" [diameter]="16"></loader>
        {{ 'PAGES.CHECKIN_RESULT.CHECK_IN' | translate }}
      </rw-button>
    </div>
  </div>
</div>