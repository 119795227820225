import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CookieService } from 'ngx-cookie-service';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { RootComponent } from './components/root/root.component';
import { TopNavComponent } from './components/organisms/top-nav/top-nav.component';
import { FooterComponent } from './components/organisms/footer/footer.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { NotFoundPageComponent } from './components/pages/not-found-page/not-found-page.component';
import { RwDropdownComponent } from './components/molecules/rw-dropdown/rw-dropdown.component';
import { LoaderComponent } from './components/molecules/loader/loader.component';
import { RwButtonComponent } from './components/molecules/rw-button/rw-button.component';
import { NewLinePipe } from './pipes/new-line.pipe';
import { RwModalComponent } from './components/molecules/rw-modal/rw-modal.component';
import { RwInputComponent } from './components/molecules/rw-input/rw-input.component';
import { MaxLengthPipe } from './pipes/max-length.pipe';
import { LoginFormComponent } from './components/organisms/login-form/login-form.component';
import { RwErrorUnderlineComponent } from './components/molecules/rw-error-underline/rw-error-underline.component';
import { RwPopupComponent } from './components/molecules/rw-popup/rw-popup.component';
import { ApiVersionInterceptor } from './api/interceptors/api-version-interceptor';
import { AuthenticateInterceptor } from './api/interceptors/authenticate-interceptor';
import { AuthGuard } from './auth-guard';
import { NotificationSnackBarComponent } from './components/molecules/notification-snack-bar/notification-snack-bar.component';
import { ErrorHandlerService } from './error-handler';
import { LoggingService } from './services/logging-service';
import { CheckInPageComponent } from './components/pages/check-in-page/check-in-page.component';
import { StepIndicatorComponent } from './components/organisms/step-indicator/step-indicator.component';
import { SummaryPageComponent } from './components/pages/summary-page/summary-page.component';
import { SafetyPageComponent } from './components/pages/safety-page/safety-page.component';
import { StartPageComponent } from './components/pages/start-page/start-page.component';
import { EndPageComponent } from './components/pages/end-page/end-page.component';
import { FormatPipe } from './pipes/format.pipe';
import { LanguageSelectComponent } from './components/organisms/language-select/language-select.component';
import { SupportPageComponent } from './components/pages/support-page/support-page.component';
import { PasteTrapDirective } from './directives/paste-trap.directive';

// AoT requires an exported function for factories
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/locale/', '.json');
}
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AuthenticateInterceptor, multi: true },
];

export const DateFormat = {
  parse: {
      dateInput: 'DD.MM.YYYY',
  },
  display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    RootComponent,
    TopNavComponent,
    FooterComponent,
    LoginPageComponent,
    NotFoundPageComponent,
    RwDropdownComponent,
    LoaderComponent,
    RwButtonComponent,
    NewLinePipe,
    RwModalComponent,
    RwInputComponent,
    MaxLengthPipe,
    LoginFormComponent,
    RwErrorUnderlineComponent,
    RwPopupComponent,
    NotificationSnackBarComponent,
    CheckInPageComponent,
    StepIndicatorComponent,
    SummaryPageComponent,
    SafetyPageComponent,
    StartPageComponent,
    EndPageComponent,
    FormatPipe,
    LanguageSelectComponent,
    SupportPageComponent,
    PasteTrapDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatSelectModule,
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'empty',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AuthGuard,
    CookieService,
    LoggingService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: MAT_DATE_FORMATS, useValue: DateFormat },
    httpInterceptorProviders ],
  bootstrap: [RootComponent],
})
export class AppModule {}
