import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalEvent } from 'src/app/model/event/global-events';
import { AppCookieService } from 'src/app/services/app-cookie-service';
import { EventHub } from '../../services/event-hub';
import { ComponentBase } from '../component-base';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
})
export class RootComponent extends ComponentBase implements OnInit {
  constructor(
    private translate: TranslateService,
    private cookieService: AppCookieService,
    private eventHub: EventHub) {
    super();
    const lang = this.cookieService.getLang();
    if (lang) {
      this.translate.use(lang);
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit() {
  }

  OnBodyClick() {
    this.eventHub.emit(GlobalEvent.BodyClick);
  }
}
