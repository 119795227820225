import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationModel } from 'src/app/model/notification/notification-model';

@Component({
  selector: 'app-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrls: ['./notification-snack-bar.component.scss']
})
export class NotificationSnackBarComponent implements OnInit {

  constructor(
    public snackRef: MatSnackBarRef<NotificationSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: NotificationModel) { }

  ngOnInit(): void {
  }

}
