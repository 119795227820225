import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { NEVER, Observable, throwError } from 'rxjs';
import { GlobalEvent } from '../model/event/global-events';
import { DeliveryModel } from '../model/shipment/delivery-model';
import { ShipmentModel } from '../model/shipment/shipment-model';
import { EventHub } from '../services/event-hub';
import { LoggingService } from '../services/logging-service';
import { NotificationService } from '../services/notification-service';
import { ExecutionStatus } from '../types/execution-status';
import { ShipmentStatus } from '../types/shipment-status';
import { ShipmentApiModel } from './model/shipment-api-model';

export abstract class ControllerBase {
  constructor(
    protected name: string,
    private http: HttpClient,
    private eventHub: EventHub,
    private notificationService: NotificationService,
    private log: LoggingService) {
  }

  protected post<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.post<T>(`api/${this.name}/${url}`, body, options);
  }

  protected get<T>(url: string, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}): Observable<T> {
    return this.http.get<T>(`api/${this.name}/${url}`, options);
  }

  protected put<T>(url: string, body: any | null, options?: {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType: 'json';
    withCredentials?: boolean;
  }): Observable<T> {
    return this.http.put<T>(`api/${this.name}/${url}`, body, options);
  }

  protected handleError(error: any) {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 401) {
        this.notificationService.showWarrningByKey('ERRORS.FORCED_LOGOUT');
        this.eventHub.emit(GlobalEvent.LogoutRequest);

        return NEVER;
      } else if (error.status === 403) {
        this.notificationService.showWarrningByKey('ERRORS.UNAUTHORISED_RESPONSE');

        return NEVER;
      } else if (error.status === 500) {
        this.notificationService.showErrorByKey('ERRORS.SYSTEM_ERROR', 5000, error.message);

        return throwError(error);
      } else {
        return throwError(error);
      }
    } else {
      return throwError(error);
    }
  }

  protected toShipmentModel(api: ShipmentApiModel): ShipmentModel {
    const model = new ShipmentModel();

    model.address = api.address;
    model.addressShort = api.addressShort;
    model.checkInDate = api.checkInDate ? new Date(api.checkInDate) : null;
    model.checkInTime = api.checkInTime;
    model.deliveries = api.deliveries?.map(d => {
      const delivery = new DeliveryModel();

      delivery.completionDate = d.completionDate ? new Date(d.completionDate) : null;
      delivery.deliveryNumber = d.deliveryNumber;
      delivery.eta = d.eta ? new Date(d.eta) : null;
      delivery.requestedDeliveryDateFrom = d.requestedDeliveryDateFrom ? new Date(d.requestedDeliveryDateFrom) : null;
      delivery.requestedDeliveryDateTo = d.requestedDeliveryDateTo ? new Date(d.requestedDeliveryDateTo) : null;
      delivery.salesOrder = d.salesOrder;
      delivery.shipTo = d.shipTo;

      return delivery;
    }) ?? null;
    model.distance = api.distance;
    model.driverMobile = api.driverMobile;
    model.driverName = api.driverName;
    model.licensePlate = api.licensePlate;
    model.loadingMeter = api.loadingMeter;
    model.messageToCarrier = api.messageToCarrier;
    model.messageToDriver = api.messageToDriver;
    model.plannedCheckInEnd = api.plannedCheckInEnd ? new Date(api.plannedCheckInEnd) : null;
    model.plannedCheckInStart = api.plannedCheckInStart ? new Date(api.plannedCheckInStart) : null;
    model.shipmentNumber = api.shipmentNumber;
    model.shippingConditions = api.shippingConditions;
    model.specialProcess = api.specialProcess;
    model.status = ShipmentStatus[api.status];
    model.executionStatus = ExecutionStatus[api.executionStatus];
    model.truckId = api.truckId;
    model.volume = api.volume;
    model.weight = api.weight;
    model.plant = api.transportPlanningPoint;

    return model;
  }
};
